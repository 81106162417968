import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import { CustomTooltip } from '@/components/atoms/CustomTooltip'
import PlusIcon from '@/components/atoms/Icon/svg/PlusIcon'
import InputField from '@/components/atoms/InputField'
import MonthSelect from '@/components/molecules/MonthSelect'
import { COMPANY_LINKS } from '@/constant/companyLinks'
import { ERROR } from '@/constant/errors'
import { PLAN_ID } from '@/constant/options'
import { organizationApi } from '@/ghgApi'
import theme from '@/theme'
import { getTime } from '@/utils/nendo'
import { OrganizationWithObjective, StoreState } from '@/zustand/slice/storeSlice'
import useStore from '@/zustand/sotre'
import { Button, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'

const useStyle = makeStyles({
    container: {
        marginTop: 20,
        maxWidth: theme.contentContainer.width,
    },
    headerCell: {
        fontSize: 14,
        fontWeight: 600,
        background: '#F7F7F7',
    },
    sitesWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: 10,
    },
    tableCell: {
        borderLeft: `1px dashed #DBDBDB`,
        '&:last-child': {
            borderRight: `1px dashed #DBDBDB`,
        },
    },
    addSiteBtn: {
        border: '1px dashed #BDBFC8',
        color: '#BEBEBE',
        height: 'fit-content',
    },
    submitBtn: {
        marginTop: 50,
        textAlign: 'center',
    },
    alignTop: {
        verticalAlign: 'top',
    },
})

interface TGeneral {
    resData: StoreState
}
type TSites = { id: number; name: string; status?: number; }

function General(props: TGeneral) {
    let initialValues: TSites[] = []
    const headers = [
        { width: 300, text: '企業名 ' },
        { width: 240, text: '年度開始月' },
        { width: '100%', text: '拠点名' },
    ]
    const data = useMemo(() => {
        if (props.resData?.organization) {
            return [
                {
                    organizationName: props.resData.organization.name,
                    startMonth: props.resData.organization.startMonth,
                    sites: props.resData.sites,
                },
            ]
        }
        return []
    }, [props.resData?.organization])
    const classes = useStyle()
    const { storeState, analysisState, setOrganization, setMessage, setAnalysisState, setSite } = useStore()
    const [organizationName, setOrganizationName] = useState('')
    const [startMonthValue, setStartMonthValue] = useState<string | number>('')
    const [sites, setSites] = useState<TSites[]>(initialValues)
    const [disabled, setDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (!props.resData.organization) {
            return
        }
        const isInValidSite = sites.length ? sites.some((site) => !site.name) : false
        if (!organizationName || isInValidSite) {
            setDisabled(true)
            return
        } else {
            setDisabled(false)
        }
    }, [organizationName, sites])

    useEffect(() => {
        if (props.resData.sites && props.resData.sites.length) {
            const sites = props.resData.sites.map((site) => ({ id: site.id, name: site.name, status: site.status }))
            setSites(sites)
        }
    }, [props.resData?.sites])

    useEffect(() => {
        if (props.resData?.organization) {
            setStartMonthValue(props.resData.organization.startMonth)
            setOrganizationName(props.resData.organization.name)
        }
    }, [props.resData?.organization])

    const handleOrganizationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrganizationName(e.target.value)
    }
    const handleChange = (e: React.ChangeEvent<any>) => {
        setSites((values) => {
            const newValues = [...values]
            const index = newValues.findIndex((vl) => vl.id === +e.target.name)
            if (index !== -1) {
                newValues[index].name = e.target.value
            }
            return newValues
        })
    }

    const handleSubmitClick = async () => {
        if (!storeState.organization) return
        try {
            const res = await organizationApi.updateOrganizationProfile({
                name: organizationName,
                startMonth: Number(startMonthValue),
                sites,
            })
            const startMonth = res.data.startMonth
            const { month, year } = getTime()
            const currentSites = [...storeState.sites]
            currentSites.forEach((site, i) => {
                sites.forEach((inputSite) => {
                    if (site.id === inputSite.id) {
                        currentSites[i].name = inputSite.name
                    }
                })
            })
            const isLarger = startMonth <= month
            const currYear = isLarger ? year : year - 1
            const lengthOfYear = currYear - 2015
            const newOrganization = { ...props.resData.organization }
            newOrganization.name = res.data.name
            newOrganization.startMonth = startMonth
            const newYears = Array.from({ length: lengthOfYear }, (_, i) => currYear - i)
            setStartMonthValue(startMonth)
            setOrganizationName(res.data.name)
            setSite(currentSites)
            setAnalysisState({
                ...analysisState,
                dateSelector: {
                    ...analysisState.dateSelector,
                    options: { ...analysisState.dateSelector.options, years: newYears },
                },
            })
            setOrganization(newOrganization as OrganizationWithObjective)
            setMessage({ message: '更新に成功しました！', type: 'success' })
            setDisabled(true)
        } catch (err) {
            setMessage({ message: '更新に失敗しました！', type: 'error' })
            console.warn(err)
        }
    }

    const handleBtnClick = () => {
        window.open(COMPANY_LINKS.REQUEST_TO_ADD_NEW_SITE, '_blank')
    }

    const tooltipTitle = useMemo(() => {
        if (storeState.organization?.planId === PLAN_ID.starter) return '5,000円/1拠点/年'
        if (storeState.organization?.planId === PLAN_ID.basic) return '20,000円/1拠点/年'
        if (storeState.organization?.planId === PLAN_ID.enterprise) return 'お問い合わせください'
        return ''
    }, [storeState.organization?.planId])

    return (
        <div className={classes.container}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((h, i) => (
                                <TableCell
                                    key={i}
                                    style={{ width: h.width, minWidth: h.width, maxWidth: h.width }}
                                    className={`${classes.tableCell} ${classes.headerCell}`}
                                    align="center"
                                >
                                    {h.text}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, i) => (
                            <TableRow key={i}>
                                <TableCell className={`${classes.tableCell} ${classes.alignTop}`}>
                                    <InputField
                                        value={organizationName}
                                        placeholder="企業名を入力"
                                        error={!organizationName.length}
                                        helperText={!organizationName.length ? ERROR.IS_REQUIRED : undefined}
                                        onChange={handleOrganizationNameChange}
                                    />
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.alignTop}`}>
                                    <MonthSelect initialValue={item.startMonth} setValue={setStartMonthValue} />
                                </TableCell>
                                <TableCell className={`${classes.tableCell}`}>
                                    <div className={classes.sitesWrapper}>
                                        {sites.map((site) => (
                                            <InputField
                                                key={site.id}
                                                value={site.name}
                                                name={site.id.toString()}
                                                placeholder="拠点名を入力"
                                                error={!site.name}
                                                helperText={!site.name ? ERROR.IS_REQUIRED : undefined}
                                                onChange={handleChange}
                                                disabled={site.status == 0}
                                            />
                                        ))}
                                        <CustomTooltip title={tooltipTitle}>
                                            <Button
                                                startIcon={<PlusIcon />}
                                                className={classes.addSiteBtn}
                                                onClick={handleBtnClick}
                                            >
                                                拠点を追加する
                                            </Button>
                                        </CustomTooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length ? (
                <div className={classes.submitBtn}>
                    <PrimaryButton width={480} height={48} onClick={handleSubmitClick} disabled={disabled}>
                        入力を保存する
                    </PrimaryButton>
                </div>
            ) : null}
        </div>
    )
}

export default General
